<template>
    
    
    <router-view name="MainPages"></router-view>
    <router-view name="ContentManagment"></router-view>
    <router-view></router-view>
</template>



<style lang="scss">

</style>
