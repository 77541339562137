import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "./styles/main.scss"


import { projectAuth } from "./firebase/config"



/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faDribbble } from '@fortawesome/free-brands-svg-icons'
import { faBehance } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { faStar } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faDribbble, faBehance, faInstagram, faLinkedin, faWhatsapp, faEnvelope, faPenToSquare, faTrash, faPlus, faGlobe, faArrowDown, faArrowUp, faStar)

//createApp(App).use(router)
//    .component('font-awesome-icon', FontAwesomeIcon).use(createPinia())
//    .mount('#app')


let app

projectAuth.onAuthStateChanged(() => {
    if (!app) {
        app = createApp(App).use(router).component('font-awesome-icon', FontAwesomeIcon).mount('#app');
    }
})



//export const app = createApp(App)

//app.use(router).component('font-awesome-icon', FontAwesomeIcon).mount('#app')