import { createRouter, createWebHistory } from 'vue-router'


import { projectAuth } from "../firebase/config"

//auth guard
const requireAuth = (to, from, next) => {
    let user = projectAuth.currentUser
        //console.log("Current user in auth guard: ", user)
    if (!user) { next({ name: 'login' }) } else {
        next()
    }
}

const checkAuth = (to, from, next) => {
    let user = projectAuth.currentUser
        //console.log("Current user in auth guard: ", user)
    if (!user) { next() } else {
        next({ name: 'cmsProfile' })
    }
}


const routes = [{
        path: '/',
        name: 'home',
        components: {
            default: () =>
                import ('@/views/HomeView.vue'),
            MainPages: () =>
                import ('@/components/NavBar.vue')
        }
    },
    {
        path: '/contact-me',
        name: 'contactMe',
        components: {
            default: () =>
                import ('@/views/ContactView.vue'),
            MainPages: () =>
                import ('@/components/NavBar.vue')
        }
    },
    {
        path: '/case-studies',
        name: 'caseStudies',
        components: {
            default: () =>
                import ('@/views/CaseStudies.vue'),
            MainPages: () =>
                import ('@/components/NavBar.vue')
        }
    },
    {
        path: '/case-studies/:id',
        name: "CaseStudy",
        components: {
            default: () =>
                import ('@/views/CaseStudy.vue'),
            MainPages: () =>
                import ('@/components/NavBar.vue')
        },
        props: true
    },
    {
        path: '/cms',
        name: "ContentManagment",
        component: () =>
            import ('@/views/ContentManagment.vue'),
        redirect: { name: 'cmsCaseStudies' },
        beforeEnter: requireAuth,
        children: [{
                path: 'case-studies',
                name: 'cmsCaseStudies',
                component: () =>
                    import ('@/components/CmsCaseStudies.vue'),
                beforeEnter: requireAuth,
            },
            {
                path: 'create',
                name: 'cmsCreate',
                component: () =>
                    import ('@/components/CmsCreate.vue'),
                beforeEnter: requireAuth,
            }, {
                path: 'edit',
                name: 'cmsEdit',
                component: () =>
                    import ('@/components/CmsEdit.vue'),
                beforeEnter: requireAuth,
                props: true
            },

        ]
    },
    {
        path: '/login',
        name: "login",
        component: () =>
            import ('@/views/LoginView.vue'),
        beforeEnter: checkAuth
    },

    {
        path: '/404',
        name: "pageNotFound",
        components: {
            default: () =>
                import ('@/components/PageNotFound.vue'),
            MainPages: () =>
                import ('@/components/NavBar.vue')
        },

    },
    {
        path: '/:pathMatch(.*)*',
        redirect: { name: 'pageNotFound' },
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0 }
        }
    },
})

export default router