import firebase from "firebase/app";
import "firebase/firestore"
import "firebase/auth"
import "firebase/storage"


const firebaseConfig = {
    apiKey: "AIzaSyCTkkQQFdIVc1e_BZLNQ9sj9_c82wY1wcE",
    authDomain: "georgemappas-7853e.firebaseapp.com",
    projectId: "georgemappas-7853e",
    storageBucket: "georgemappas-7853e.appspot.com",
    messagingSenderId: "99309875610",
    appId: "1:99309875610:web:b66eda3a68f9cfa2feca23",
    measurementId: "G-MF3FJER464"

};

firebase.initializeApp(firebaseConfig)


const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()
const projectStorage = firebase.storage()
const storageRef = projectStorage.ref();


const timestamp = firebase.firestore.FieldValue.serverTimestamp

export { projectAuth, projectFirestore, projectStorage, timestamp, storageRef }